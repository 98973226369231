<template lang="pug">
  archive-layout(:doc="doc")
</template>

<script>
import store from '@/store'
import ArchiveLayout from '@/components/ArchiveLayout'
let doc
const getDoc = () => store.dispatch('getArchive')
export default {
  name: 'Archive',
  components: { ArchiveLayout },
  data () {
    return {
      doc
    }
  },
  async beforeRouteEnter (to, from, next) {
    doc = await getDoc()
    next()
  },
  async created () {
    this.doc = doc || await getDoc()
  },
  metaInfo () {
    if (doc) {
      // const title = this.$prismic.asText(doc.title)
      return {
        // title,
        meta: this.$store.getters.meta({
          // title,
          descrip: this.$prismic.asText(doc.meta_description),
          img: doc.meta_image?.url
        })
      }
    }
    return {}
  }
}
</script>

<style>
</style>
